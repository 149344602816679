<template>
    <b-modal :id="`assignmentModal`" centered size="md" @hide="close">

            <template #modal-title> </template>
            <div :disabled="loading">
                <slot></slot>
            </div>
            <template #modal-footer>
                <div class="d-flex align-items-center justify-content-between w-100">
                    <b-button variant="primary" class="ml-auto mr-36">Kiracı Ata</b-button>
                </div>
            </template>
    </b-modal>
</template>
<style lang="scss">
#assignmentModal {
    @media (min-width: 576px){
        .modal-dialog {
        max-width: 61.53846rem;
        margin: 1.75rem auto;
        }
    }

    .modal-content{
        border-radius: 0.38462rem;
    }
    .modal-header {
        border-bottom: none !important;
    }

    .modal-footer {
        border-top: none !important
    }
}
</style>

<script>
export default {
    name: 'AssignmentModal',
    props: {
        id: {
            type: String,
            default: new Date().getTime().toString(),
        },
        show: Boolean,
        title: String,
        confirmLabel: {
            type: String,
            default: 'Kiracı Ata',
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        confirm() {
            this.close();
        },
        close() {
            this.$bvModal.hide(`assignmentModal`);
            this.$emit('closeAssigment', false);
        },
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.$bvModal.show(`assignmentModal`);
            } else this.$bvModal.hide(`assignmentModal`);
        },
    },
};
</script>
  